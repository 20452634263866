<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
      scrollable
  >
    <v-card>
      <v-toolbar
          dark>
        <v-card-title class="text-h5">

          <span>{{ title }} Required Document</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined
                  dense
                  :error="$v.required_document.title.$error"
                  v-model="required_document.title"
              >
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.title">{{ errors.title[0] }}</span>
              <span class="text-danger" v-if="$v.required_document.title.$error">Title Field is required</span>

            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-select
                  outlined
                  dense
                  :error="$v.required_document.type.$error"
                  v-model="required_document.type"
                  :items="document_types"
                  item-value="value"
                  item-text="title"
              >
                <template v-slot:label>
                  Document Type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.required_document.type.$error">Type is required</span>

            </v-col>
            <v-col cols="12" md="12">
              <v-autocomplete
                  :items="countries"
                  dense
                  item-text="title"
                  item-value="id"
                  label="Country "
                  outlined :error="$v.required_document.country_id.$error"
                  prepend-inner-icon="fas fa-globe-americas"
                  v-model="required_document.country_id">
                <template v-slot:item="slotProps">
                  <i :class="`em em-flag-${slotProps.item.iso_2.toLowerCase()}`"
                     class="mr-2"></i>
                  {{ slotProps.item.title }}
                </template>
                <template v-slot:selection="data">
                  <i :class="`em em-flag-${data.item.iso_2.toLowerCase()}`"
                     class="mr-2"></i>
                  {{ data.item.title }}
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.required_document.country_id.$error">Country Field is required</span>
              <span class="text-danger" v-if="errors.country_id">{{ errors.country_id[0] }}</span>
            </v-col>
            <v-col cols="6" md="4">
              Status
              <v-switch
                  v-model="required_document.is_active"
                  :label="required_document.is_active?'Active':'Inactive'"
                  color="primary"></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark
            medium
            @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            medium
            :loading="loading"
            @click="createOrUpdate()"
            v-if="checkIsAccessible('required-document', 'create') || checkIsAccessible('required-document', 'edit')"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {required, email, requiredIf} from "vuelidate/lib/validators";
import RequiredDocumentService from "@/services/required-document/RequiredDocumentService";
import CountryService from "@/services/country/CountryService";

const country = new CountryService();
const requiredDocument = new RequiredDocumentService();
export default {
  validations: {
    required_document: {
      title: {required},
      country_id: {required},
      type: {required},
    }
  },
  data() {
    return {
      title: "",
      dialog: false,
      edit: false,
      loading: false,
      errors: [],
      countries: [],
      document_types: [
        {title: "Academic", value: "academic"},
        {title: "Visa", value: "visa"}
      ],
      required_document: {
        title: '',
        country_id: '',
        is_active: true,
        type: null,
      }
    }
  },
  methods: {

    closeDialog() {
      this.dialog = false;
      this.errors = [];
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    createRequiredDocument() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Add";
    },
    editRequiredDocument(item) {
      this.openDialog();
      this.edit = true;
      this.required_document = item;


      this.title = "Edit";
    },


    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit) this._create(this.required_document);
        else this.update(this.required_document);
      }
    },
    _create: function (data) {
      this.loading = true;
      requiredDocument
          .create(data)
          .then(response => {
            this.$snotify.success("Category created successfully");
            this.resetForm();
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update: function (data) {
      this.loading = true;
      requiredDocument
          .update(this.required_document.id, data)
          .then(response => {
            this.$snotify.success("Category updated successfully");
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.openDialog();
      this.required_document = {
        title: '',
        country_id: '',
        is_active: true,
      }
      this.$v.$reset();
      this.errors = [];
    },
    getCountries() {
      country
          .getAllCountry()
          .then((response) => {
            this.countries = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
    },


  },
  mounted() {
    this.getCountries();
  },
  computed: {}

}
</script>
